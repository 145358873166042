import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { CompanyHeader } from "@homesusa/auth";

const apiUrl = `${process.env.REACT_APP_API_JSONIMPORT}/api`;

interface GenerateApiKeyRequest {
  expiration?: Date;
}

export const generateApiKey = async (
  companyId: string,
  data?: GenerateApiKeyRequest
): Promise<string> => {
  const config: AxiosRequestConfig = {
    headers: { [CompanyHeader]: companyId }
  };
  const response = await axios.post<
    GenerateApiKeyRequest,
    AxiosResponse<{ apiKey: string }>
  >(`${apiUrl}/generate`, data, config);
  return response.data.apiKey;
};
