import React from "react";

import { Button, TextField, Stack, Divider } from "@mui/material";
import { generateApiKey } from "core/services/json-import.service";
import { CompanyContext } from "modules/company/contexts";

export const JsonImportManagement = (): JSX.Element => {
  const [apiKey, setApiKey] = React.useState<string>();
  const {
    company: { id }
  } = React.useContext(CompanyContext);
  return (
    <Stack spacing={1}>
      <Divider />
      <Button
        onClick={async () => {
          const key = await generateApiKey(id);
          setApiKey(key);
        }}
      >
        Generate Api Key
      </Button>
      <TextField label="Api key" multiline={true} value={apiKey} rows={2} />
    </Stack>
  );
};
